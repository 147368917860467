import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "../components/clases.module.css"

export default ({ data }) => {
  const portada = data.portada
  const portadaM = data.portadaM

  return (
    <Layout portadaDesk={portada} portadaMob={portadaM} title="Clases">
      <SEO title="Clases" />
      <main>
        <div className={styles.mainContainer}>
          <div className={styles.clase}>
            <div className={styles.titleContainer}>
              <h3>Privadas</h3>
            </div>
            <div className={styles.textContainer}>
              <p>
                Trabajo personalizado de la mano de un instructor capacitado,
                donde descubrirás la técnica de cómo ganar fuerza, flexibilidad
                y control del cuerpo.
              </p>
            </div>
            <a href="https://wa.me/595981597069?text=Hola%20Allinea!%20Estoy%20interesado%20en%20las%20clases%20privadas">
              <button type="button" className={styles.button}>
                CONSULTAR
              </button>
            </a>
          </div>
          <div className={styles.clase}>
            <div className={styles.titleContainer}>
              <h3> Semi Privadas</h3>
            </div>
            <div className={styles.textContainer}>
              <p>
                Dos personas con un instructor capacitado para enseñarles la
                técnica del Método. Es un trabajo divertido para realizar con un
                amigo, familiar o simplemente con alguien que tenga tus mismas
                ganas de aprender.
              </p>
            </div>
            <a href="https://wa.me/595981597069?text=Hola%20Allinea!%20Estoy%20interesado%20en%20las%20clases%20semiprivadas">
              <button type="button" className={styles.button}>
                CONSULTAR
              </button>
            </a>
          </div>
          <div className={styles.clase}>
            <div className={styles.titleContainer}>
              <h3>Grupal</h3>
            </div>
            <div className={styles.textContainer}>
              <p>
                Hasta 4 personas para experimentar la energía de una clase de
                Mat grupal con un instructor capacitado.
              </p>
            </div>
            <a href="https://wa.me/595981597069?text=Hola%20Allinea!%20Estoy%20interesado%20en%20las%20clases%20grupales">
              <button type="button" className={styles.button}>
                CONSULTAR
              </button>
            </a>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ClasesQuery {
    portadaM: file(relativePath: { eq: "clases-p-m.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    portada: file(relativePath: { eq: "clases-p.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
